::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-corner {
  background-color: hsl(0deg 0% 90%);
}

::-webkit-scrollbar-thumb {
  border: 4px solid hsl(0deg 0% 0% / 0%);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: hsl(0deg 0% 67%);
}
