/* Normal */
@font-face {
  font-family: 'Wotfard';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Wotfard/Wotfard-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/Wotfard/Wotfard-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Wotfard/Wotfard-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Wotfard/Wotfard-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Wotfard/Wotfard-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Wotfard/Wotfard-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Wotfard/Wotfard-Bold.woff2') format('woff2');
}

/* Italic */
@font-face {
  font-family: 'Wotfard';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/Wotfard/Wotfard-ThinItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/Wotfard/Wotfard-ExtraLightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/Wotfard/Wotfard-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Wotfard/Wotfard-RegularItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Wotfard/Wotfard-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/Wotfard/Wotfard-SemiBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Wotfard';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Wotfard/Wotfard-BoldItalic.woff2') format('woff2');
}

html {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  font-feature-settings: 'salt';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}
